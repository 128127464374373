import { Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useQueryClient } from '@tanstack/react-query';
import { DeviceType, TripEventType } from 'harmony-constants';
import { L } from 'harmony-language';
import React, { CSSProperties } from 'react';
import { OrgQueryKeys } from '../../../api/config';
import { useLoadsKey } from '../../../api/config-hooks';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { useTractorTrailer } from '../../../api/queries/use-tractor-trailer';
import { StopsTable } from '../../../components/shared/load-table/stops-table';
import { type GrossWeightObject, type Load, type OrganizationLocation, type Stop, type User } from '../../../types';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { getDuration, localDateTimeDisplay } from '../../../utils/date-time-utils';
import { getConvertedDistance } from '../../../utils/distance-utils';
import { HereMap, HereMapRefObject } from '../../here-maps/here-map';
import { HereMapRectangle } from '../../here-maps/here-map-rectangle';
import { useOrgLocationColorProxy } from '../../here-maps/use-org-location-color-proxy';
import { CircularLoading } from '../../shared/circular-loading';
import { LoadProvider, useLoadContext } from '../../shared/load-context';
import { ActualDistance, ActualDuration, EstimatedDistance, EstimatedDuration, LoadDateNoTooltip, LoadDriver, LoadTractor, LoadTrailer } from '../../shared/load-table/load-table-cells';
import { getGrossWeight } from '../../shared/load-table/utils/load-utils';
import { HereMapOrderContent } from '../edit-create-order/here-map-order-content';
import { useLoadsColumns } from '../use-loads-columns';
import { DeliveryLogEventRow, SyntheticDeliveryLogEventRow } from './delivery-log-event-row';
import { DeliveryLogRefetchButton } from './delivery-log-refetch-button';
import './delivery-log.css';
import { useDeviceLocations } from './queries/use-device-locations';
import { useTripEvents } from './queries/use-trip-events';
import { SwapEventsButton } from './swap-events-button';
import { useWeights } from '../../shared/hooks/use-weights';
import { convertSpeedToDisplay } from '../../../utils/unit-conversion';
import { useUser } from '../../../api/queries/use-user';

const DeliveryLogStopActualsHeader: React.FC = () => {
    const className = 'stopActualsRow';

    return (
        <div  className={`sharedGridRow ${className}`}>
            <span className='sharedGridItem'>{L.location()}</span>
            <span className='sharedGridItem'>{L.started()}</span>
            <span className='sharedGridItem'>{L.completed()}</span>
            <span className='sharedGridItem'>{L.actualDuration()}</span>
            <span className='sharedGridItem'>{L.actualDistance()}</span>
            <span className='sharedGridItem'>{L.grossWeight()}</span>
            <span className='sharedGridItem'>{L.completedBy()}</span>
        </div>
    );
}

interface DeliveryLogStopActualsRowProps {
    index: number;
    stop: Stop;
    grossWeightObj: GrossWeightObject | undefined
}

const DeliveryLogStopActualsRow: React.FC<DeliveryLogStopActualsRowProps> = (props) => {
    const { index, stop, grossWeightObj } = props;
    const className = 'stopActualsRow';
    const { data: locations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: users = [], isLoading: isLoadingUsers } = useOrganizationQuery<User[]>(OrgQueryKeys.usersCarriers);

    const site = locations?.find(x => x.id === stop.organizationLocationId);
    const startedAt = stop.startedAt ? localDateTimeDisplay(stop.startedAt) : null;
    const completedAt = stop.completedAt ? localDateTimeDisplay(stop.completedAt) : null;
    const completedByUser = users?.find(x => x.id === stop.completedByUserId);
    const { convertedDistance, abbreviation } = getConvertedDistance(stop.distanceMeters);

    if (isLoadingUsers) {
        return (
            <div>
                <Skeleton width={'100%'} height={'2rem'} />
            </div>
        );
    }

    return (
        <div key={index} className={`sharedGridRow ${className}`}>
            <span className='sharedGridItem'>
                {site ? toSiteDisplayName(site.name, site.description) : null}
            </span>
            <span className='sharedGridItem' title={stop.startedAt ? stop.startedAt.toString() : ''}>
                {startedAt}
            </span>
            <span className='sharedGridItem' title={stop.completedAt ? stop.completedAt.toString() : ''}>
                {completedAt}
            </span>
            <span className='sharedGridItem'>
                {stop.timeSeconds ? getDuration(stop.timeSeconds * 1000) : null}
            </span>
            <span className='sharedGridItem'>
                {convertedDistance && `${convertedDistance} ${abbreviation}`}
            </span>
            {grossWeightObj ?
                <Tooltip
                    arrow
                    title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {grossWeightObj?.tooltipText}
                        </div>
                    }
                    placement='top'
                >
                    <span className='sharedGridItem'>
                        {grossWeightObj?.grossWeight}
                    </span>
                </Tooltip>
            : <span className='sharedGridItem' /> // Include dummy span for styling if no grossWeightObj
            }
            <span className='sharedGridItem'>
                {completedByUser ? completedByUser.name : null}
            </span>
        </div>
    );
}

type DeliveryLogHeaderProps = {
    title: string;
    style?: CSSProperties;
};

const DeliveryLogHeader: React.FC<DeliveryLogHeaderProps> = (props) => {
    const { title, style } = props;

    return (
        <Typography gutterBottom sx={{ fontSize: '1.4rem', borderBottom: '1px solid #ccc', ...style }}>{title}</Typography>
    );
};

const renderSkeleton = () => {
    return (
        <>
            <Skeleton width={'100%'} height={'2rem'} />
            <Skeleton width={'100%'} height={'2rem'} />
        </>
    );
};

type SharedEventsAndMapProps = {
    type: 'user' | 'tractor' | 'trailer' | 'order';
    id: number;
    startDate?: string;
    endDate?: string;
    stops?: Stop[];
};

const SharedEventsAndMap: React.FC<SharedEventsAndMapProps> = (props) => {
    const { type, id, startDate, endDate, stops } = props;
    const { data: tripEvents = [], isLoading: isLoadingEvents, refetch: refetchTripEvents, isFetching: isFetchingEvents } = useTripEvents(type, id, startDate, endDate);
    const { data: deviceLocations, isLoading: isLoadingDeviceLocations, refetch: refetchDeviceLocations } = useDeviceLocations(type, id, startDate, endDate);
    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { user } = useUser();

    const mapRef = React.useRef<HereMapRefObject>(null);
    const [mobileEventsShown, setMobileEventsShown] = React.useState(true);
    const [currentMaxSpeedIndex, setCurrentMaxSpeedIndex] = React.useState(0);

    const filteredMobileDeviceLocations = React.useMemo(() => {
        return deviceLocations?.filter(x => x.accuracy !== null && x.accuracy < 100 && x.device?.type === DeviceType.Mobile) || [];
    }, [deviceLocations]);
    const filteredTrailerDeviceLocations = React.useMemo(() => {
        return deviceLocations?.filter(x => x.accuracy && x.accuracy < 100 && x.device?.type === DeviceType.Trailer) || [];
    }, [deviceLocations]);
    const orgLocationRectangles = React.useMemo(() => {
        return locations.map((orgLocation) =>
            orgLocation.customGeofence && orgLocation.customGeofence.bbox &&
                <HereMapRectangle
                    key={orgLocation.id}
                    pointA={{ lat: orgLocation.customGeofence.bbox.topLeft.latitude, lng: orgLocation.customGeofence.bbox.topLeft.longitude }}
                    pointB={{ lat: orgLocation.customGeofence.bbox.bottomRight.latitude, lng: orgLocation.customGeofence.bbox.bottomRight.longitude }}
                    strokeColor={useOrgLocationColorProxy()[orgLocation.orgLocationType.id].stroke}
                    fillColor={useOrgLocationColorProxy()[orgLocation.orgLocationType.id].fill}
                    type='avoidance' //this should probably be renamed, type decides if when you hit the recenter map button if the rectangle should be included in the recenter math
                    changeable={false}
                >
                    <div>
                        <span className='map-tooltip-label-name'>{orgLocation.name}</span>
                        {orgLocation.description && <span className='map-tooltip-label-desc'>{orgLocation.description}</span>}
                    </div>
                </HereMapRectangle>
        );
    }, [locations]);

    const mobileEvents = tripEvents.filter(e => e.device?.type === DeviceType.Mobile);
    const trailerEvents = tripEvents.filter(e => e.device?.type === DeviceType.Trailer);

    const isLoadingOrFetchingEvents = isLoadingEvents || isFetchingEvents;
    const isLoadingControlOptions = isLoadingDeviceLocations || isLoadingEvents;

    const unitSystem = user.contact?.units || user.organization?.units;
    const maxSpeed = Math.max(...filteredMobileDeviceLocations.map(dl => dl.speed || 0));
    const maxSpeedDeviceLocations = filteredMobileDeviceLocations.filter(dl => dl.speed === maxSpeed);

    const refetchAll = () => {
        refetchTripEvents();
        refetchDeviceLocations();
    };

    const moveMap = (lat: number, lng: number) => {
        mapRef.current?.centerMap(lat, lng, 18);
    };

    const swap = () => {
        setMobileEventsShown(!mobileEventsShown);
    }

    const controlOptions = {
        showMobileLocations: Boolean(filteredMobileDeviceLocations.length),
        showTrailerLocations: Boolean(filteredTrailerDeviceLocations.length),
        showMobileHeading: Boolean(filteredMobileDeviceLocations.length),
        showTrailerHeading: Boolean(filteredTrailerDeviceLocations.length),
        showMobileEvents: Boolean(mobileEvents.length),
        showTrailerEvents: Boolean(trailerEvents.length),
        showAugerOn: trailerEvents.some(x => x.type === TripEventType.AugerOn),
    }

    const manuallyCompletedStops = stops && stops.filter(stop => stop.metadata?.manuallyCompleted);

    const handleSpeedOnClick = () => {
        moveMap(
            maxSpeedDeviceLocations[currentMaxSpeedIndex].latitude,
            maxSpeedDeviceLocations[currentMaxSpeedIndex].longitude
        );
        setCurrentMaxSpeedIndex(prev =>
            prev < maxSpeedDeviceLocations.length -1 ? ++prev : 0
        );
    };

    return (
        <>
            {type != 'order' ? <DeliveryLogRefetchButton refetchFunc={refetchAll} /> : null}
            <DeliveryLogHeader title={L.events()} />
            {trailerEvents.length > 0 && <SwapEventsButton swap={swap} mobileEventsShown={mobileEventsShown} />}
            <div className='eventsContainer'>
                <div>
                    <div>
                        {isLoadingOrFetchingEvents ? renderSkeleton() :
                            mobileEventsShown ? mobileEvents.map((event, i) => {
                                return <DeliveryLogEventRow
                                    key={event.id}
                                    event={event}
                                    i={i}
                                    locations={locations}
                                    moveMap={moveMap}
                                    stops={stops}
                                />
                            }) :
                            trailerEvents.map((event, i) => {
                                return <DeliveryLogEventRow
                                    key={event.id}
                                    event={event}
                                    i={i}
                                    locations={locations}
                                    moveMap={moveMap}
                                />
                            })
                        }
                    </div>
                    {manuallyCompletedStops && Boolean(manuallyCompletedStops.length) && <>
                        <DeliveryLogHeader title={L.otherEvents()} style={{ paddingTop: '10px' }} />
                        <div>
                            <SyntheticDeliveryLogEventRow manuallyCompletedStops={manuallyCompletedStops} />
                        </div>
                    </>}
                </div>
                {isLoadingControlOptions ? (
                    <div className='map'>
                        <CircularLoading />
                    </div>
                ) : (
                    <div>
                        <HereMap
                            ref={mapRef}
                            controlOptions={isLoadingControlOptions ? undefined : controlOptions}
                            className='map'
                        >
                            {orgLocationRectangles}
                            <HereMapOrderContent
                                mapRef={mapRef}
                                deviceLocations={filteredMobileDeviceLocations}
                                trailerDeviceLocations={filteredTrailerDeviceLocations}
                                tripEvents={tripEvents}
                            />
                        </HereMap>
                        <div onClick={() => handleSpeedOnClick()}>
                            {`${L.maxSpeed()}: ${convertSpeedToDisplay(unitSystem)(maxSpeed)}`}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

type DeliveryLogContentProps = {
    loadId: number;
};

const DeliveryLogContent: React.FC<DeliveryLogContentProps> = (props) => {
    const { loadId } = props;
    const { load } = useLoadContext();
    const { tractors, trailers } = useTractorTrailer();
    const tractor = tractors?.find(t => t.id === load.tractorId);
    const trailer = trailers?.find(t => t.id === load.trailerId);
    const { refetch: refetchTripEvents } = useTripEvents('order', loadId);
    const { refetch: refetchDeviceLocations } = useDeviceLocations('order', loadId);
    const { stopColumns } = useLoadsColumns();
    const queryClient = useQueryClient();
    const loadsKey = useLoadsKey();
    const { convertFromGramsDisplay } = useWeights();


    const topLeftRows = React.useMemo(() => ([
        { header: L.loadInfo(), render: LoadDateNoTooltip },
        { header: L.estimatedDuration(), render: EstimatedDuration },
        { header: L.estimatedDistance(), render: EstimatedDistance },
        { header: L.actualDuration(), render: ActualDuration },
        { header: L.actualDistance(), render: ActualDistance },
        { header: L.driver(), render: LoadDriver },
        { header: L.tractor(), render: LoadTractor },
        { header: L.trailer(), render: LoadTrailer },
    ]), []);

    const refetchAll = () => {
        refetchDeviceLocations();
        refetchTripEvents();
        queryClient.invalidateQueries(loadsKey);
    };

    return (
        <div className='page'>
            <DeliveryLogRefetchButton refetchFunc={refetchAll} />
            <div className='topGrid'>
                <div>
                    <DeliveryLogHeader title={L.loadInfo()} />
                    {
                        topLeftRows.map((c, i) => {
                            return (
                                <div key={i} className='row'>
                                    <span className='label'>{c.header}:</span>
                                    <c.render />
                                </div>
                            );
                        })
                    }
                </div>
                <div className='stopsGrid'>
                    <div>
                        <DeliveryLogHeader title={L.stopActuals()} />
                        <div>
                            <DeliveryLogStopActualsHeader />
                            {load.stops.map((stop, i) => {
                                let grossWeightObj;
                                if (stop.completedAt) {
                                    const applicableStops = load.stops.filter(s => !(s.sequence > stop.sequence));
                                    grossWeightObj = getGrossWeight(tractor, trailer, applicableStops, convertFromGramsDisplay);
                                }
                                return (
                                    <DeliveryLogStopActualsRow
                                        key={i}
                                        index={i}
                                        stop={stop}
                                        grossWeightObj={grossWeightObj}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <SharedEventsAndMap
                type={'order'}
                id={loadId}
                stops={load.stops}
            />
            <DeliveryLogHeader title={L.stops()} />
            <div className='stopsContainer'>
                <StopsTable columns={stopColumns} />
            </div>
        </div>
    );
};

type ResourceLogContentProps = {
    resourceType: DeliveryLogResourceType;
    resourceId: number;
    startDate: string;
    endDate: string;
};

const ResourceLogContent: React.FC<ResourceLogContentProps> = (props) => {
    const { resourceType, resourceId, startDate, endDate } = props;

    return (
        <div className='page'>
            <SharedEventsAndMap
                type={resourceType}
                id={resourceId}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    );
}

export type DeliveryLogResourceType = 'user' | 'tractor' | 'trailer';

type DeliveryLogProps = {
    isResourceLog: boolean;
    load?: Load;
    driverId?: number;
    resourceType?: DeliveryLogResourceType;
    resourceId?: number;
    startDate?: string | null;
    endDate?: string | null;
};

export const DeliveryLog: React.FC<DeliveryLogProps> = (props) => {
    const { load, isResourceLog, resourceType, resourceId, startDate, endDate } = props;

    if (load) {
        return (
            <LoadProvider load={load} disableConflicts={true}>
                <DeliveryLogContent loadId={load.id} />
            </LoadProvider>
        );
    } else if (isResourceLog && resourceType && resourceId && startDate && endDate) {
        return (
            <ResourceLogContent
                resourceType={resourceType}
                resourceId={resourceId}
                startDate={startDate}
                endDate={endDate}
            />
        )
    }
    return (null);
};
